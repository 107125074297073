/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

$color2: #0030D1;

:root {

	// COLOURS
	// ---------------

	--color1:               #FDC734;
	--color2:               #0030D1;
	--color3:               #D6F2F4;

	// TYPOGRAPHY
	// ---------------

	--text-color:           var(--color2);
	--text-base-size:        16px;
	--base-line-height:      1.612;
	--base-type-spacing:     1.875rem;

	--font-family-primary:   "Raleway", Helvetica, Arial, sans-serif;
	--font-family-secondary: "Roboto", var(--font-family-primary);
	--font-family-headings:  var(--font-family-secondary);

	--headings-color:        var(--color2);
	--hr-color:              var(--color2);

}